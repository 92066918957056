import { ContributionRequestCard } from '@/components/roadmap/contributionRequest'
import { FeatureRequestCard } from '@/components/roadmap/featureRequest'
import { features } from '@/components/roadmap/features'
import { createFileRoute } from '@tanstack/react-router'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@ui/components/ui/accordion'
import { Badge } from '@ui/components/ui/badge'
import { Label } from '@ui/components/ui/label'
import { cn } from '@ui/lib/utils'
import {
    CircleEllipsis,
    FolderPen,
    LocateFixed,
    MoveRight,
} from 'lucide-react'

export const Route = createFileRoute('/_dashboard/roadmap/')({
    component: () => {
        return (
            <div className="p-8">
                <div className="flex items-center space-x-2">
                    <LocateFixed/>
                    <Label className="text-primary text-2xl">Our Roadmap</Label>
                </div>
                <div className="flex items-center space-x-10 space-y-8">
                    <p className="flex-2 text-muted-foreground">
                    We believe in building our product in public. If you’d like to prioritize a feature, feel free to submit a request! We’d love to hear your suggestions to help shape a product that addresses your pain points.
                    </p>
                    <div className="flex items-center space-x-2 p-2 border rounded-none bg-muted-foreground/20">
                        <FeatureRequestCard/>
                        <MoveRight />
                    </div>
                </div>

                {/* Table Headings */}
                <div className="relative m-auto  max-w-screen-2xl">
                    <div className="flex items-center p-2  mt-8">
                        <div className="flex-1 flex items-center space-x-2 text-muted-foreground">
                            <FolderPen />
                            <p className="font-bold">Features</p>
                        </div>
                        <div className="flex-1 flex items-center space-x-2 text-muted-foreground justify-center">
                            <CircleEllipsis />
                            <p className="font-bold">Status</p>
                        </div>
                    </div>

                    {/* Table Content */}

                    {features.map((item, index) => (
                        <div key={index} className="border-t p-4">
                          <div className={cn(item.title==='Bring Your Own Database, LLM, Email, Telephony for VoIP' ? 'p-4 border rounded-md border-primary/50 bg-primary/15' : '')}>
                            <div className="flex items-center group">
                                <div className="flex-1">
                                    <div className="flex items-center space-x-3 justify-start">
                                        <div className="text-muted-foreground group-hover:animate-bounce">
                                            {item.icon}
                                        </div>
                                        <Accordion
                                            type="single"
                                            collapsible
                                            className="border-none"
                                        >
                                            <AccordionItem
                                                value="item-1"
                                                className="last:border-none"
                                            >
                                                <AccordionTrigger>
                                                    <div className="font-semibold pr-2">
                                                        {item.title}
                                                    </div>
                                                </AccordionTrigger>
                                                <AccordionContent className="text-muted-foreground">
                                                    {item.description}
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </div>

                                <div className="flex-1 flex justify-center">
                                    <Badge
                                        className={cn(
                                            item.status === 'Under Development'
                                                ? 'bg-blue-400 '
                                                : item.status === 'Done'
                                                  ? 'bg-emerald-400 '
                                                  : item.status === 'Lined Up'
                                                    ? 'bg-yellow-400'
                                                    : 'bg-muted-foreground/30',
                                            'rounded-md  p-2 font-semibold text-black'
                                        )}
                                    >
                                        {item.status}
                                    </Badge>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    ))}

<div>

* Are you a builder and willing to ideate , innovate and build something cool with us ? You can  <ContributionRequestCard/> and we will get in touch with you at the earliest.

</div>
                   
                </div>
            </div>
        )
    }, 
})
