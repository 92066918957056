import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@ui/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from '@ui/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@ui/components/ui/form'
import { Input } from '@ui/components/ui/input'
import { Label } from '@ui/components/ui/label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@ui/components/ui/select'
import { TextArea } from '@ui/components/ui/textarea'
import { cn } from '@ui/lib/utils'
import { SendHorizonal } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { features } from './features'
import { useUserMutation } from '@/mutations/user'

const NewFeatures = [
    { title: 'All features', icon: null, status: null, description: null },
    {
        title: 'More than one feature',
        icon: null,
        status: null,
        description: null,
    },
    ...features,
]

const formSchema = z.object({
    feature: z.string().optional(),
    briefDescription: z
        .string()
        .min(5, {
            message: 'Description must consist of at least 5 characters',
        })
        .max(5000, {
            message: 'Description must not exceed 5000 characters',
        }),
    linkedIn: z.string().url(),
})

export const ContributionRequestCard = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { sendFeedback } = useUserMutation()

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            feature:'',
            briefDescription: '',
            linkedIn: '',
        },
    })

    const { isSubmitting, isValid } = form.formState
    const descriptionValue = form.watch('briefDescription') || '' // Access description field value

    const cb = () => {
        setIsOpen(false)
        form.reset()
    }
    
    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        const concatenatedOutput=`Feature:${values.feature}\nBrief Description:${values.briefDescription}\nLinkedIn : ${values.linkedIn}`


        const feedbackObject = { feedback: concatenatedOutput }

        await sendFeedback.mutateAsync({ ...feedbackObject, cb })
    }

    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button
                    variant={'link'}
                    className="px-0"
                    onClick={handleToggle}
                >
                    request to contribute
                </Button>
            </DialogTrigger>
            <DialogContent showClose={!isSubmitting} className="min-w-fit">
                <DialogTitle>Let us know about your interest</DialogTitle>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            control={form.control}
                            name="feature"
                            render={({ field }) => (
                                <FormItem>
                                    <Label>Select a feature</Label>
                                    <Select onValueChange={field.onChange}>
                                        <SelectTrigger className="w-full">
                                            <SelectValue placeholder="Select a feature you want to work on"/>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>
                                                    Features
                                                </SelectLabel>
                                                {NewFeatures.map(
                                                    (item, index) => (
                                                        <SelectItem
                                                            value={item.title}
                                                            key={index}
                                                        >
                                                            <div className="flex items-center space-x-3">
                                                                <div className="flex-2 font-semibold text-muted-foreground">
                                                                    {item.icon &&
                                                                        item.icon}
                                                                </div>
                                                                <div className="flex-1 font-semibold">
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </SelectItem>
                                                    )
                                                )}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="briefDescription"
                            render={({ field }) => (
                                <FormItem>
                                    <Label>
                                        Why do you want to work with us ?
                                    </Label>
                                    <FormControl>
                                        <TextArea
                                            className="w-full h-32"
                                            placeholder="Tell us about your motivation and your interests"
                                            shouldResize={false}
                                            {...field}
                                        />
                                    </FormControl>
                                    <p
                                        className={cn(
                                            descriptionValue.length > 5000
                                                ? 'text-red-600'
                                                : 'text-muted-foreground',
                                            'text-sm  flex justify-end'
                                        )}
                                    >
                                        {descriptionValue.length} / 5000
                                    </p>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="linkedIn"
                            render={({ field }) => (
                                <FormItem>
                                    <Label>LinkedIn Profile</Label>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Paste link to your LinkedIn profile here"
                                        />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex justify-between">
                            <Button
                                variant={'outline'}
                                type="button"
                                onClick={() => form.reset()}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="default"
                                type="submit"
                                disabled={!isValid || isSubmitting}
                            >
                                <div className="flex space-x-2 items-center">
                                    <p>Submit Request</p>
                                    <SendHorizonal className="w-4 h-4" />
                                </div>
                            </Button>
                        </div>
                    </form>
                </Form>

                <p className="max-w-sm text-sm text-muted-foreground">
                    Have a technical issue? Reach out to us at{' '}
                    <a
                        href="mailto:team@lipy.ai"
                        className="underline hover:text-primary"
                    >
                        team@lipy.ai
                    </a>
                </p>
            </DialogContent>
        </Dialog>
    )
}
