import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@ui/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from '@ui/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@ui/components/ui/form'
import { TextArea } from '@ui/components/ui/textarea'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Input } from '@ui/components/ui/input'
import { Label } from '@ui/components/ui/label'
import { SendHorizonal } from 'lucide-react'
import { cn } from '@ui/lib/utils'
import { useUserMutation } from '@/mutations/user'

const featureFormRequestSchema = z.object({
    title: z.string().min(2, {
        message: 'Title must consist of at least 2 characters',
    })
    .max(200, {
        message: 'Title must not exceed 200 characters',
    }),
    description: z
        .string()
        .min(5, {
            message: 'Description must consist of at least 5 characters',
        })
        .max(4000, {
            message: 'Description must not exceed 4000 characters',
        }),
})

export const FeatureRequestCard = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { sendFeedback } = useUserMutation()
   
    const form = useForm<z.infer<typeof featureFormRequestSchema>>({
        resolver: zodResolver(featureFormRequestSchema),
        defaultValues: {
            title: '',
            description: ''
        },
    })

    const { isSubmitting, isValid } = form.formState
    const titleValue = form.watch('title') || '' 
    const descriptionValue = form.watch('description') || '' 

    const cb = () => {
        setIsOpen(false)
        form.reset()
    }

    const handleSubmit = form.handleSubmit(async (val) => {
        const concatenatedOutput = `Title: ${val.title}\nDescription: ${val.description}`
        const feedbackObject = { feedback: concatenatedOutput }
    
        await sendFeedback.mutateAsync({ ...feedbackObject, cb })
    })
    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant={'link'} className="text-xl" onClick={handleToggle}>
                    Make a feature request
                </Button>
            </DialogTrigger>
            <DialogContent showClose={!isSubmitting}>
                <DialogTitle>Let us know about the feature in your mind</DialogTitle>
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4"
                    >
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <Label>Title</Label>
                                    <FormControl>
                                        <Input {...field} placeholder="Enter a short title for the feature" />
                                    </FormControl>
                                    <p className={cn(titleValue.length>200 ? 'text-red-600' : 'text-muted-foreground' , 'text-sm  flex justify-end')}>
                                        {titleValue.length} / 200
                                    </p>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <Label>Description</Label>
                                    <FormControl>
                                        <TextArea
                                            className="w-full h-32"
                                            placeholder="Tell us more about the feature"
                                            shouldResize={false}
                                            {...field}
                                        />
                                    </FormControl>
                                    <p className={cn(descriptionValue.length>4000 ? 'text-red-600' : 'text-muted-foreground' , 'text-sm  flex justify-end')}>
                                        {descriptionValue.length} / 4000
                                    </p>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex justify-between">
                            <Button
                                variant={'outline'}
                                type="button"
                                onClick={() => form.reset()}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="default"
                                type="submit"
                                disabled={isSubmitting || !isValid}
                            >
                                <div className='flex space-x-2 items-center'>
                                    <p>Submit Request</p>
                                    <SendHorizonal className='w-4 h-4'/>
                                </div>
                            </Button>
                        </div>
                    </form>
                </Form>

                <p className="max-w-sm text-sm text-muted-foreground">
                    Have a technical issue? Reach out to us at{' '}
                    <a
                        href="mailto:team@lipy.ai"
                        className="underline hover:text-primary"
                    >
                        team@lipy.ai
                    </a>
                </p>
            </DialogContent>
        </Dialog>
    )
}
