import * as React from 'react'
import { useAuthStore } from '@/store/auth'
import { useGlobalStore } from '@/store/global'
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarRail,
    SidebarSeparator,
} from '@ui/components/ui/sidebar'
import {
    Blocks,
    BookOpen,
    BotIcon,
    Building,
    Home,
    LineChart,
    Map,
    Ticket,
    User,
} from 'lucide-react'

import { HelpCard } from '../custom/helpCard'
import OrgSwitcher from '../custom/orgSwitcher'
import { NavMain } from './nav-main'
import { NavUser } from './nav-user'

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { org: orgId } = useGlobalStore()

    const { user } = useAuthStore()

    const data = {
        navMain: [
            {
                title: 'Home',
                url: `/`,
                icon: Home,
                isActive: true,
                items: [],
            },
            {
                title: 'Support Tickets',
                url: `/org/${orgId}/tickets`,
                icon: Ticket,
                items: [],
            },
            {
                title: 'Knowledge Base',
                url: `/org/${orgId}/knowledge-base`,
                icon: BookOpen,
                items: [
                    {
                        title: 'Intents',
                        url: `/org/${orgId}/knowledge-base/intents`,
                    },
                    {
                        title: 'Ticket Forms',
                        url: `/org/${orgId}/knowledge-base/forms`,
                    },
                    {
                        title: 'Custom Flows',
                        url: `/org/${orgId}/knowledge-base/flows`,
                    },
                ],
            },
            {
                title: 'Assistant',
                url: `/org/${orgId}/assistant`,
                icon: BotIcon,
                items: [],
            },
            {
                title: 'Analytics',
                url: `/org/${orgId}/reports`,
                icon: LineChart,
                items: [],
            },
            {
                title: 'Integrations',
                url: `/org/${orgId}/integrations`,
                icon: Blocks,
                items: [],
            },
        ],
        projects: [
            {
                title: 'Organization',
                url: `/org/${orgId}/settings`,
                icon: Building,
                items: [
                    {
                        title: 'General',
                        url: `/org/${orgId}/settings`,
                    },
                    {
                        title: 'Members',
                        url: `/org/${orgId}/settings/members`,
                    },
                    {
                        title: 'Usage',
                        url: `/org/${orgId}/settings/usage`,
                    },
                ],
            },
            {
                title: 'Account',
                url: '/account',
                icon: User,
                items: [
                    {
                        title: 'Profile',
                        url: '/account',
                    },
                    {
                        title: 'Billing',
                        url: '/account/billing',
                    },
                    {
                        title: 'Notifications',
                        url: '/account/notifications',
                    },
                    {
                        title: 'Appearance',
                        url: '/account/appearance',
                    },
                ],
            },
        ],
        roadmap:[
          {
            title: 'Roadmap',
            url: `/roadmap`,
            icon: Map,
            items: [],
        },
        ]
    }
    return (
        <Sidebar variant="sidebar" {...props}>
            <SidebarHeader>
                <SidebarMenu>
                    <SidebarMenuItem>
                        <SidebarMenuButton size="default" asChild>
                            <OrgSwitcher />
                        </SidebarMenuButton>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarHeader>
            <SidebarSeparator className='my-1 -mx-0.5'/>
            <SidebarContent>
                <NavMain items={data.navMain} label={'General'} />
                <NavMain items={data.projects} label={'Settings'} />
                <SidebarGroup className="mt-auto">
                <NavMain items={data.roadmap} label={''}/>
                    <SidebarMenuButton className='pl-4'>
                        <HelpCard trigger="IconButton" />
                    </SidebarMenuButton>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter>
                <NavUser user={user} />
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    )
}
