import { ReactNode } from 'react'
import configuration from '@/configuration'
import { cn } from '@repo/ui/lib/utils'
import { Link, useNavigate } from '@tanstack/react-router'
import { buttonVariants } from '@ui/components/ui/button'
import { SatelliteDish, Telescope } from 'lucide-react'

import useNavigatorOnLine from '@/hooks/network'

const ErrorPage = ({
    children,
    message,
    error,
}: {
    children?: ReactNode
    error?: Error
    message?: string
}) => {
    const online = useNavigatorOnLine()

    if (error) {
        message = error.message
    }

    const navigate = useNavigate()
    const lengthy = (message?.length || 0) > 100

    if (message?.includes('Failed to fetch')) message = 'Failed to fetch data!'

    if (message?.includes('Failed to authenticate')) {
        navigate({
            to: '/logout',
        })
    }

    return (
        <>
            <div className="flex flex-col flex-1 items-center justify-center space-y-4 text-center">
                {online ? (
                    <Telescope width={75} height={75} strokeWidth={1.5} />
                ) : (
                    <SatelliteDish width={75} height={75} strokeWidth={1.5} />
                )}
                <div
                    className={cn(
                        'text-center w-fit max-w-md',

                        lengthy && 'space-y-2'
                    )}
                >
                    {error && !error.message && <h2>There was an error!</h2>}

                    <h3 className={'font-medium text-center'}>
                        {online
                            ? message && !lengthy
                                ? message
                                : 'Something went wrong!'
                            : 'It seems you are offline. Please check you internet!'}
                    </h3>
                    {online && lengthy && (
                        <p className="text-center text-sm">{message}</p>
                    )}
                    <p
                        className={cn(
                            'px-4 max-w-sm text-sm text-muted-foreground m-auto text-center',
                            lengthy && 'border p-1 rounded-md'
                        )}
                    >
                        Contact us on{' '}
                        <a
                            href={`mailto:${configuration.support.email}`}
                            className="underline hover:text-primary"
                        >
                            {configuration.support.email}
                        </a>{' '}
                        for further assistance.
                    </p>
                </div>
                <div>
                    {children || (
                        <Link
                            to={'/'}
                            className={cn(
                                buttonVariants({
                                    size: 'sm',
                                    variant: 'outline',
                                })
                            )}
                        >
                            Back to Home
                        </Link>
                    )}
                </div>
            </div>
        </>
    )
}

export default ErrorPage
