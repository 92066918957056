import { AssistantIntentsTable } from '@repo/types/db'
import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export type IntentsResult = {
    items: Array<Omit<AssistantIntentsTable, 'embedding'> & { count: number }>
    total: number
    page: number
    limit: number
}

export const intentsQueryOptions = (opts: {
    limit: number
    orgId: string
    groupId: string
    page: number
    q?: string
    sort?: string
}) => {
    const searchParams = new URLSearchParams({
        page: String(opts.page),
        limit: String(opts.limit),
        ...(opts.q && { q: opts.q }),
        ...(opts.sort && { sort: opts.sort }),
    } as Record<string, string>)

    const key = 'intents'

    return queryOptions<IntentsResult, any>({
        queryKey: [key, opts],
        queryFn: ({ signal }) =>
            apiQuery({
                url: `/org/${opts.orgId}/intents/${opts.groupId}?${searchParams.toString()}`,
                method: 'GET',
                signal,
            }),
        placeholderData: keepPreviousData,
    })
}

export type IntentCountResult = {
    active: number
    disabled: number
}

export const intentCountQueryOptions = ({ orgId }: { orgId: string }) => {
    return queryOptions<IntentCountResult>({
        queryKey: ['intents-count', orgId],
        queryFn: () =>
            apiQuery({
                url: `/org/${orgId}/intents/count`,
                method: 'GET',
            }),
    })
}
