import { Fragment, ReactNode, useEffect } from 'react'
import { removeAuthCache, setAuthCache } from '@/firebase/auth'
import { firebase_auth } from '@/firebase/config'
import { useAuthStore } from '@/store/auth'
import { useGlobalStore } from '@/store/global'
import { useMatches, useNavigate, useRouter } from '@tanstack/react-router'
import { getIdTokenResult } from 'firebase/auth'
import { toast } from 'sonner'

import { queryClient } from '@/lib/queryClient'

import { FullPageLoader } from '../custom/loader'

export const Auth = ({ children }: { children: ReactNode }) => {
    const matches = useMatches()
    const router = useRouter()
    const navigate = useNavigate({ from: '/' })
    const { user, loading, update } = useAuthStore()
    const { userId, reset } = useGlobalStore()

    const pathname = matches[matches.length - 1]?.pathname || '/'

    const handleDeactivated = async () => {
        navigate({ to: '/deactivated', replace: true })
    }
    useEffect(() => {
        const unsubscribe = firebase_auth.onAuthStateChanged((curr) => {
            if (curr) {
                if (!userId || userId !== curr.uid) {
                    queryClient.clear()
                    reset(curr.uid)
                }
                update({ user: curr, loading: false })
            } else {
                update({ loading: false })
            }
        })

        return () => unsubscribe()
    }, [])

    useEffect(() => {
        if (loading) return

        if (!user) {
            if (pathname.includes('/auth')) return
            const redirect_url = ['/auth', '/logout'].some((s) =>
                router.latestLocation.href.includes(s)
            )
                ? '/'
                : router.latestLocation.href

            setAuthCache({
                redirect_url,
            })
            navigate({
                to: `/auth`,
                replace: true,
            })
            return
        }

        getIdTokenResult(user)
            .then((res) => {
                update({ token: res.token })
                if (
                    res.claims.deactivated &&
                    !pathname.startsWith('/deactivated')
                ) {
                    handleDeactivated()
                }
            })
            .catch(() => toast.error('Failed to fetch token result'))

        removeAuthCache()
    }, [user, loading, pathname, router.latestLocation.href])
    return (
        <Fragment>
            {(loading || !user) && !pathname.includes('/auth') ? (
                <>
                    <FullPageLoader />
                </>
            ) : (
                <>{children}</>
            )}
        </Fragment>
    )
}
