import { useMemo } from 'react'
import { singleOrgQueryOptions } from '@/queries/orgs'
import { useQuery } from '@tanstack/react-query'
import {
    createFileRoute,
    MatchRoute,
    Outlet,
    useRouter,
    useRouterState,
} from '@tanstack/react-router'
import { Separator } from '@ui/components/ui/separator'
import {
    SidebarInset,
    SidebarProvider,
    SidebarTrigger,
} from '@ui/components/ui/sidebar'

import { FullPageLoader } from '@/components/custom/loader'
import { AppSidebar } from '@/components/nav/app-sidebar'
import Navbar from '@/components/nav/navbar'

export const Route = createFileRoute('/_dashboard')({
    pendingComponent: FullPageLoader,
    // eslint-disable-next-line no-use-before-define
    component: LayoutComponent,
})

function LayoutComponent() {
    const params = Route.useParams() as any
    const orgId = params.orgId || ''
    const { data: org } = useQuery(singleOrgQueryOptions({ orgId }))

    const loading = useRouterState({ select: (s) => s.status === 'pending' })
    const router = useRouter()

    const match = router.matchRoute({
        to: '/org/$orgId/tickets/$id',

        params: { orgId } as any,
    })

    const pathname = router.latestLocation.pathname
    const navTitle = useMemo(() => {
        return pathname.endsWith(`/org/${orgId}`)
            ? 'Dashboard'
            : pathname.endsWith(`/org/${orgId}/integrations`)
              ? 'Integrations'
              : pathname.endsWith(`/org/${orgId}/tickets`)
                ? 'Support Tickets'
                : pathname.endsWith(`/org/${orgId}/assistant`)
                  ? 'Assistant'
                  : pathname.endsWith(`org/${orgId}/reports`)
                    ? 'Reports'
                    : pathname.endsWith(`/roadmap`)
                      ? 'Our Roadmap'
                      : pathname.endsWith('/account')
                        ? 'Profile'
                        : pathname.endsWith('/account/billing')
                          ? 'Bills and Invoices'
                          : pathname.endsWith('/account/notifications')
                            ? 'Notifications Setting'
                            : pathname.endsWith('/account/appearance')
                              ? 'Appearance'
                              : pathname.endsWith(`org/${orgId}/settings`)
                                ? 'Organization Settings'
                                : pathname.endsWith(
                                        `org/${orgId}/settings/members`
                                    )
                                  ? 'Members'
                                  : pathname.endsWith(
                                          `org/${orgId}/settings/usage`
                                      )
                                    ? 'Usage'
                                    : pathname.includes(
                                            `org/${orgId}/knowledge-base/intents`
                                        )
                                      ? 'Intents'
                                      : pathname.endsWith(
                                              `org/${orgId}/knowledge-base/forms`
                                          )
                                        ? 'Forms'
                                        : pathname.endsWith(
                                                `org/${orgId}/knowledge-base/flows`
                                            )
                                          ? 'Custom Flows'
                                          : ''
    }, [pathname])

    return (
        <div className="flex flex-1 w-full h-screen overflow-hidden">
            <SidebarProvider className="w-full">
                <AppSidebar />
                <SidebarInset className="h-10">
                    {org?.member_role !== 'agent' && (
                        <>
                            <MatchRoute
                                to="/org/$orgId/knowledge-base"
                                params={{ orgId }}
                                fuzzy
                            >
                                {/* {(match) => match && <KBNav />} */}
                            </MatchRoute>
                        </>
                    )}
                    {new RegExp(`/org/${orgId}/tickets/[^/]+$`).test(
                        pathname
                    ) ? (
                        <></>
                    ) : (
                        <div className="flex items-center bg-[hsl(var(--sidebar-background))]">
                            <SidebarTrigger className="ml-2" />
                            <Separator
                                orientation="vertical"
                                className="mx-2 h-6"
                            />
                            <div className="w-full">
                                {!match && <Navbar title={navTitle} />}
                            </div>
                        </div>
                    )}

                    <Separator />
                    <div className="h-screen overflow-auto flex-1 inline-flex flex-col">
                        {loading ? <FullPageLoader /> : <Outlet />}
                    </div>
                </SidebarInset>
            </SidebarProvider>
        </div>
    )
}
