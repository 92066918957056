import configuration from '@/configuration'
import { firebase_auth } from '@/firebase/config'
import { differenceInSeconds } from 'date-fns'
import { toast } from 'sonner'

import { delay } from './utils'

let retryCount = 0

export const getToken = async () => {
    let counter = 0
    let token = null

    const expTime = (firebase_auth as any).currentUser?.stsTokenManager
        ?.expirationTime

    const handle = async () => {
        const expiresInSeconds = differenceInSeconds(
            expTime,
            new Date().getTime()
        )
        token = await firebase_auth.currentUser?.getIdToken(
            retryCount !== 0 || expiresInSeconds <= 0
        )
    }
    if (token || counter > 10) return token

    // Wait for the interval to stop
    while (counter < 10) {
        if (token) break
        counter++
        if (counter > 0) await delay(500)
        handle()
    }
    return token
}

export const apiQuery = async (
    {
        url,
        host,
        method = 'GET',
        body,
        headers,
        signal,
    }: {
        url: string
        host?: string
        method?: 'GET' | 'PATCH' | 'DELETE' | 'POST'
        headers?: HeadersInit
        body?: Record<string, any>
        signal?: AbortSignal
    },
    options?: {
        throwError?: boolean
        showToast?: boolean
        allow_unauthenticated?: boolean
        error_message?: string
    }
) => {
    const opts = {
        throwError: options?.throwError || true,
        showToast: options?.showToast || false,
        allow_unauthenticated: options?.allow_unauthenticated || false,
        error_message: options?.error_message || null,
    }

    const token = await getToken()

    if (!firebase_auth.currentUser) return null

    if (!token && !opts?.allow_unauthenticated) {
        return toast.error('Oh no! Error', {
            description: 'Unable to send request!',
        })
    }

    const defaultheaders: HeadersInit = {
        'Content-Type':
            !body && method !== 'GET' ? 'text/plain' : 'application/json',
    }

    if (token && !opts?.allow_unauthenticated) {
        defaultheaders.Authorization = `Bearer ${token}`
    }

    return await fetch((host || configuration.customer_api.url) + url, {
        method: method,
        headers: { ...defaultheaders, ...headers },
        body: JSON.stringify(body),
        signal,
    })
        .then(async (res) => {
            retryCount = 0
            const r = await res.json()
            if (!res.ok) throw r

            return r
        })
        .catch(async (err) => {
            if (
                retryCount === 0 &&
                err?.details?.firebase_code === 'auth/id-token-expired'
            ) {
                retryCount++
                await apiQuery({ url, host, method, body, headers }, options)
            }

            if (opts?.showToast) {
                toast.error('Oh no! Error', {
                    description:
                        opts.error_message ||
                        err.message ||
                        'Something went wrong',
                })
            }

            if (opts?.throwError) {
                throw err
            } else {
                return null
            }
        })
}
