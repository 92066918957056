import { createContext, ReactNode, useContext, useEffect } from 'react'
import { handleListeners } from '@/listeners'
import { useAuthStore } from '@/store/auth'
import debounce from 'debounce'
import { Socket } from 'socket.io-client'
import { toast } from 'sonner'

import { socket } from '@/lib/socket'

export interface ISocketContext {
    socket: Socket | null
}

const values = {
    socket: null,
}

socket.on('connect', function () {
    console.info('Connected')
})

socket.on('disconnect', function () {
    console.info('Disconnected')
})

socket.on('reconnect', function () {
    console.info('Reconnected')
})

socket.on(
    'error',
    debounce(
        (err) =>
            toast.error('Error!', {
                description: err.message || 'Something went wrong!',
            }),
        300
    )
)

socket.on(
    'connect_error',
    debounce(
        (err: any) =>
            console.error('Connection Error!', {
                description: err.message || 'Something went wrong!',
            }),
        300
    )
)

socket.on('message', handleListeners)

const SocketContext = createContext<ISocketContext>(values)

export const SocketProvider = ({ children }: { children: ReactNode }) => {
    const { token } = useAuthStore()

    useEffect(() => {
        const timer = setTimeout(() => {
            if (socket.connected) {
                socket.disconnect()
            }
            if (!token) return

            socket.auth = {
                token: token,
            }
            socket.connect()
        }, 500)
        return () => clearTimeout(timer)
    }, [token])

    useEffect(() => {
        return () => {
            socket.disconnect()
        }
    }, [])

    return (
        <SocketContext.Provider
            value={{
                socket,
            }}
        >
            {children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext)
}
