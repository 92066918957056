import { SocketActions } from '@repo/types/socket'
import { toast } from 'sonner'

import { socket } from '@/lib/socket'

export const emitMessage = <T extends keyof SocketActions['business']>(
    action: T,
    data: SocketActions['business'][T]
) => {
    switch (action) {
        case 'org:action':
        case 'ticket:action':
        case 'ticket:message':
        case 'ticket:update':
        case 'ticket:call':
            break
        default:
            return toast.error(`Invalid event - ${action}`)
    }

    socket.emit('message', { action, data }, (err: any) => {
        console.error(err)
    })
}
