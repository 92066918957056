import {
    BadgeHelp,
    BrainCircuit,
    Cable,
    FileText,
    FolderLock,
    LayoutGrid,
    LibraryBig,
    MailOpen,
    MessageCircleMore,
    NotebookPen,
    PhoneIncoming,
    PhoneOutgoing,
    PieChart,
    Ticket,
    Workflow,
} from 'lucide-react'

export const features = [
    {
        title: 'AI and Manual Chat Assistant',
        icon: (<MessageCircleMore />),
        status: 'Done',
        description:
            'The chat assistant can be trained on various intents and provides real-time replies based on the most relevant responses. Organization can also opt for manual chatting, bypassing AI/LLM involvement for a more personalized experience.',
    },
    {
        title: 'Ticket Analysis and Reporting',
        icon: <PieChart />,
        status: 'Done',
        description:
            'Provides comprehensive analysis and reporting on support tickets, identifying key metrics and trends to improve customer support performance and track resolution efficiency.',
    },
    {
        title: 'Forms and Form Builder',
        icon: <NotebookPen />,
        status: 'Under Development',
        description:
            'An intuitive form builder allowing users to create, customize, and integrate forms into their customer support workflows, streamlining data collection and improving customer service efficiency.',
    },
    {
        title: 'P2P Voice and Video Calling',
        icon: <PhoneIncoming />,
        status: 'Under Development',
        description:
            'Enables seamless peer-to-peer voice and video calling within the platform, allowing agents to provide direct, real-time support for more complex customer queries.',
    },
    {
        title: 'Revamping Support Ticketing with New Interface',
        icon: <Ticket />,
        status: 'Under Development',
        description:
            'A redesigned, user-friendly ticketing interface aimed at enhancing navigation, improving the ticket resolution workflow, and offering an improved visual and functional experience for support teams.',
    },
    {
        title: 'Intent Training Through PDFs, DOCs, and Web-Scraping',
        icon: <FileText />,
        status: 'Under Development',
        description:
            'Allows the chat assistant to learn intents directly from documents (PDFs, DOCs) and web content, enhancing its accuracy and making it more knowledgeable based on the provided content.',
    },
    {
        title: 'Chat Assistant Integration with Shopify',
        icon: <img src={'/logo/shopify.svg'} alt="Shopify Logo" className="w-7" />,
        status: 'Under Development',
        description:
            'Enables seamless integration of the chat assistant with Shopify stores, allowing businesses to provide real-time, AI-driven support directly on their e-commerce platforms.',
    },
    {
        title: 'Customized Flow',
        icon: <Workflow />,
        status: 'Lined Up',
        description:
            'Provides the ability to design custom workflows, enabling businesses to automate complex customer service tasks and personalize support experiences based on customer needs.',
    },
    {
        title: 'Integration of Email in Ticketing System: Gmail and Outlook',
        icon: <MailOpen />,
        status: 'Lined Up',
        description:
            'Integrates Gmail and Outlook into the ticketing system, allowing support teams to manage email-based inquiries directly within the platform and streamline email communication with customers.',
    },
    {
        title: 'Revamping the Knowledge Base Section',
        icon: <LibraryBig />,
        status: 'Lined Up',
        description:
            'An improved knowledge base interface designed for better searchability, content management, and user experience, helping customers find answers to their questions quickly and easily.',
    },
    {
        title: 'Lipy AI App: Android & iOS',
        icon: <LayoutGrid />,
        status: 'Lined Up',
        description: 'A dedicated mobile app for both Android and iOS platforms, allowing users to manage customer interactions, access support tools, and view analytics on the go.',
    },
    {
        title: 'Integration of Communication Channels: WhatsApp, Facebook Messenger, Instagram',
        icon: <Cable />,
        status: 'Lined Up',
        description: 'Seamless integration with popular social media messaging channels like WhatsApp, Facebook Messenger, and Instagram, enabling businesses to manage and respond to customer inquiries from a single platform.',
    },
    {
        title: 'Outbound Telephony Setup, Call Recording, Call Transcription',
        icon: <PhoneOutgoing />,
        status: 'Lined Up',
        description: 'A complete outbound calling solution with features for call recording and transcription, allowing businesses to track and log calls, enhance call quality, and generate insights from call transcriptions.',
    },
    {
        title: 'Help Center and Feedback Management Builder',
        icon: <BadgeHelp />,
        status: 'Lined Up',
        description: 'A customizable help center and feedback management tool that empowers businesses to create support resources and gather customer feedback efficiently, improving self-service options and customer satisfaction.',
    },
    {
        title: 'Bring Your Own Database, LLM, Email, Telephony for VoIP',
        icon: <FolderLock />,
        status: 'Lined Up',
        description: 'Flexibility to integrate existing databases, language models (LLM), email systems, and VoIP telephony setups, allowing businesses to maintain control over their data and preferred technologies.',
    },
    {
        title: 'Industry-Wise Fine Tuning of LLM',
        icon: <BrainCircuit />,
        status: 'Ideation Stage',
        description: 'Specialized fine-tuning of language models tailored to specific industries, ensuring that the AI provides more accurate, context-aware responses based on industry-specific terminology and requirements.',
    }
 ] as const
