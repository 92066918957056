
import { useState } from 'react'
import { logOut } from '@/firebase/auth'
import { useAuthStore } from '@/store/auth'
import { Link, useNavigate } from '@tanstack/react-router'

import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '@repo/ui/components/ui/avatar'
import { Button } from '@repo/ui/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@repo/ui/components/ui/dropdown-menu'
import { CircleUser, LogOut, ReceiptText } from 'lucide-react'

export const UserMenuContent = ({user} : {user : any})=>{

    const [loggingOut, setLoggingOut] = useState(false)
    const navigate = useNavigate()

    const handleLogout = async (e: any) => {
        e.preventDefault()
        setLoggingOut(true)
        await logOut()
        navigate({ to: '/auth' })
    }
    return(
        <div>
           <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage src={user.photoURL!} alt={user.displayName} />
                  <AvatarFallback>
                            {user.displayName
                                ?.split(' ')
                                .map((chunk: any) => chunk[0])
                                .join('')
                                .toUpperCase()}
                        </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user.displayName}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
    <DropdownMenuSeparator />
    <DropdownMenuGroup>
        <DropdownMenuItem asChild>
            
            <Link to={'/account'}>
            <div className='flex items-center justify-between space-x-2'>
            <CircleUser className='w-6 h-6'/>
            <div> Profile</div>

            </div>
            
           
            </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
            <Link to={'/account/billing'}>
            <div className='flex items-center justify-between space-x-2'>
            <ReceiptText className='w-6 h-6'/>
            <div>Billing</div>

            </div>
            
            </Link>
        </DropdownMenuItem>
    </DropdownMenuGroup>
    <DropdownMenuSeparator />
    <DropdownMenuItem
        onClick={handleLogout}
        disabled={loggingOut}
        className="space-x-2"
    >
        <span>{loggingOut ? 'Logging out...' : (<div className='flex items-center justify-between space-x-2'>
            <LogOut className='w-6 h-6'/>
            <div>Log out</div>

            </div>)} </span>
    </DropdownMenuItem>
        </div>

        
    )
}

export const UserNav = () => {
   
    const { user } = useAuthStore()
    
    if (!user) return null

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="relative h-9 w-9 rounded-full p-0"
                >
                    <Avatar className="h-8 w-8 border">
                        <AvatarImage
                            src={user.photoURL!}
                            alt={user.displayName!}
                            className="object-cover"
                        />
                        <AvatarFallback>
                            {user.displayName
                                ?.split(' ')
                                .map((chunk: any) => chunk[0])
                                .join('')
                                .toUpperCase()}
                        </AvatarFallback>
                    </Avatar>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
               <UserMenuContent user={user}/> 
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
