import { Link, useRouter } from '@tanstack/react-router'
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from '@ui/components/ui/collapsible'
import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuAction,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
} from '@ui/components/ui/sidebar'
import { cn } from '@ui/lib/utils'
import { ChevronRight, type LucideIcon } from 'lucide-react'

export function NavMain({
    items,
    label,
}: {
    items: {
        title: string,
        url: string,
        icon: LucideIcon,
        items?: {
            title: string,
            url: string,
        }[];
    }[],
    label: string
}) {
    const router = useRouter() // Get the current route
    const currentRoute = router.state.location.pathname // Current URL path

    return (
        <SidebarGroup>
            <SidebarGroupLabel className="font-semibold text-md">{label}</SidebarGroupLabel>
            <SidebarMenu>
                {items.map((item) => {
                    // Determine if this item or any of its sub-items match the current route
                    const isActive =
                        currentRoute === item.url ||
                        item.items?.some((subItem) => currentRoute === subItem.url)

                    return (
                        <Collapsible
                            key={item.title}
                            asChild
                            defaultOpen={isActive} // Open if active
                        >
                            <SidebarMenuItem>
                                {/* Main Item */}
                                <SidebarMenuButton asChild tooltip={item.title}>
                                    <Link
                                        to={item.url}
                                        className={cn(
                                            'flex items-center gap-2 p-2 rounded',
                                            isActive
                                                ? 'bg-primary/20 text-primary font-bold hover:bg-primary/20 hover:text-primary hover:font-bold'
                                                : ''
                                        )}
                                    >
                                        <item.icon className={cn(isActive ? 'font-bold':'font-semibold')}/>
                                        <span className={cn(isActive ? 'font-bold':'font-semibold')}>{item.title}</span>
                                    </Link>
                                </SidebarMenuButton>

                                {/* Sub-Items */}
                                {item.items?.length ? (
                                    <>
                                        <CollapsibleTrigger asChild>
                                            <SidebarMenuAction className="data-[state=open]:rotate-90">
                                                <ChevronRight />
                                                <span className="sr-only">Toggle</span>
                                            </SidebarMenuAction>
                                        </CollapsibleTrigger>
                                        <CollapsibleContent>
                                            <SidebarMenuSub>
                                                {item.items.map((subItem) => {
                                                    const isSubActive = currentRoute === subItem.url

                                                    return (
                                                        <SidebarMenuSubItem
                                                            key={subItem.title}
                                                        >
                                                            <SidebarMenuSubButton
                                                                asChild
                                                            >
                                                                <Link
                                                                    to={subItem.url}
                                                                    className={cn(
                                                                        'flex items-center gap-2 p-2 rounded',
                                                                        isSubActive
                                                                            ? 'bg-primary/20 text-primary font-bold hover:bg-primary/20 hover:text-primary hover:font-bold'
                                                                            : ''
                                                                    )}
                                                                >
                                                                    <span>{subItem.title}</span>
                                                                </Link>
                                                            </SidebarMenuSubButton>
                                                        </SidebarMenuSubItem>
                                                    )
                                                })}
                                            </SidebarMenuSub>
                                        </CollapsibleContent>
                                    </>
                                ) : null}
                            </SidebarMenuItem>
                        </Collapsible>
                    )
                })}
            </SidebarMenu>
        </SidebarGroup>
    )
}
